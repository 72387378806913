import originAxios from 'axios'
import { Notify } from 'vant'

export default function axios(option) {
	return new Promise((resolve, reject) => {
		const instance = originAxios.create({
			baseURL: 'http://api.985csgo.com/api',
			timeout: 5000
		});	
		//token
		instance.interceptors.request.use(config => {
			localStorage.getItem('token') && (config.headers.Authorization = localStorage.getItem('token'))
			return config
		}, err => {
			return err
		})
		// 接口错误拦截
		instance.interceptors.response.use(res => {
			res.status != 200 && (res.data = { code: 0, message: '网络错误' })

			if (res.data.code == 1 || res.data.code == 200)
				return res
			else
				Notify({ color: 'red',background: '#fff',type: 'danger', message: res.data.message })
			return Promise.reject(res)

		})

		//传入对象进行网络请求
		instance(option).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}
