<template>
	<div id="app">
		<router-view />
		<!-- <div @click="get2"></div> -->
		<!-- <audio preload="auto" loop muted id="audio"  :src="require('./assets/images/q2/MP3/base64.mp3')"></audio> -->
		<!-- <div class="audos" @click="changeOn" :class="isOff?'isOff':'isOn'">
		</div> -->
	</div>
</template>

<script>


	import {
		Join_channel,
		PersonalInfo,
		user_status
	} from './network/api.js'
	import md5 from 'blueimp-md5'
	import {
		mapMutations
	} from 'vuex'
	window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  }
	export default {
		name: 'app',
		data() {
			return {
				path: "wss://api.985csgo.com//ws", //网址
				socket: null,
				client_id: '', //客户端id
				audioUrl:require('./assets/images/q2/MP3/base64.mp3'),//声音文件
				isOff:true
			}
		},

		created() {
			this.init()

			if (!this._isMobile()) {
				 window.location.href = '//www.985csgo.com'
			}
			this.get2()
			this.PersonalInfos()
			
			this.setTime();
			
			
		},

		mounted() {
			// setTimeout(() => {
			// 	 this.get2()
			// }, 500)
			// this.audioAutoPlay('audio');
            // // 自动播放音乐效果，解决微信自动播放问题
            // document.addEventListener('touchstart',this.audioAutoPlay,false);
            // document.addEventListener('WeixinJSBridgeReady', this.audioAutoPlay,false);
            // let oAudio = document.querySelector("#audio");
            // oAudio.onended = function () {//播放完毕，重新循环播放
            //     oAudio.load();
            //     oAudio.play();
            // }
        },

		methods: {
			// get2(){
			// 	const videoDom = document.getElementById('audio');
			// 	videoDom.muted = false;
			// },
		// 	changeOn(){
        //         let oAudio = document.querySelector("#audio");
        //        if(this.isOff){
        //         oAudio.play();//让音频文件开始播放     
        //        }else{
        //         oAudio.pause();//让音频文件暂停播放 
        //        }
        //        this.isOff = !this.isOff;
        //    },
        //    audioAutoPlay() {
        //         let audio = document.getElementById('audio');
        //             this.isOff = false;
        //             audio.play();
        //         document.removeEventListener('touchstart',this.audioAutoPlay);
        //     },
			setTime(){ 
			//每隔一分钟运行一次保存方法
			this.timer = setInterval(()=>{
					this.PersonalInfos()
					setTimeout(() => {
						this.saveList();
					}, 500)
				},60000)
			},
			saveList(){
				if(localStorage.getItem('token')){
					user_status(this.ids).then((res) => {
						this.item = res.data.data
						// console.log(this.item)
					})
				}else{
					
				}
			},
			PersonalInfos(){
				PersonalInfo().then((res)=>{
					// console.log(res.data.message);
					this.ids=res.data.message.id
				})
			},
			//判断访问设备
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag
			},

			//箱子对战(websocket)
			init() {
				if (typeof(WebSocket) === "undefined") {
					alert("您的浏览器不支持socket")
				} else {
					// 实例化socket
					this.socket = new WebSocket(this.path)
					// 监听socket连接
					this.socket.onopen = this.open
					// 监听socket错误信息
					this.socket.onerror = this.error
					// 监听socket消息
					this.socket.onmessage = this.getMessage
				}
			},
			open() {
				// console.log("socket连接成功")
				this.Timing()
			},
			error() {
				// console.log("连接错误")
			},
			//处理返回数据
			getMessage(msg) {
				let Data = JSON.parse(msg.data)
				// console.log(Data)
				switch (Data.MsgType) {
					case "connect": //返回客户端id
						this.authentication(Data.Data) //去认证
						break;
					case "auth": //认证成功
						this.ToGroup() //加入对战群组
						break;
					case "CreateGroup": //创建房间
						this.CreateGroup(Data.Data)
						break;
					case "joinGroup": //加入房间
						this.joinGroup(Data.Data)
						break;
					case "endGroup": //对战结束
						this.endGroup(Data.Data)
						break;
					default:
						// startGroup => 对战开始
				}
			},
			send(params) {
				this.socket.send(params)
			},
			close() {
				// console.log("socket已经关闭")
			},


			//认证
			authentication(v) {
				this.client_id = v.client_id
				let ran = Math.floor(Math.random() * Math.pow(10, 20)).toString()
				let data = {
					MsgType: 'auth',
					token: '',
					string: ran,
					time: new Date().getTime(),
					app_id: 'csgoskins',
				}
				const temp = v.client_id + ran + data.time + data.app_id + 'h2T*V%&cQx6traMr'
				data.token = md5(temp)
				this.send(JSON.stringify(data))
			},

			//进入对战群组频道
			ToGroup() {
				// console.log(v)
				Join_channel(this.client_id).then((res) => {
					// console.log(res)
				})
			},


			//定时心跳
			Timing() {
				setInterval(() => {
					// console.log('心跳')
					this.send('G')
				}, 18000)
			},

			...mapMutations([
				'CreateGroup',
				'joinGroup',
				'endGroup'
			])
		},
		destroyed() {
			// 销毁监听
			this.socket.onclose = this.close
		}
	}
</script>

<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		
	}
	body {
		
		font-family: '微软雅黑';
		background: rgb(32, 34, 43);
		
	}
	#Index{
		position: relative;
	}
	.audos{
      position: fixed;
      right: 10px;
      bottom: 80px;
      width: 20px;
      height: 20px;
      color: #fff;
      z-index: 999;
	//   background: #000;
	  padding: 20px;
	  font-weight: 900;
    }
	.isOn{
		width: 0px;
		height: 0px;
		position: fixed;
		z-index: 2000;
		
		// -webkit-animation: rotating 1.2s linear infinite;
		// animation: rotating 1.2s linear infinite;
		background: url("./assets/images/q2/pkin/yes1.png") 0 0 no-repeat;
		background-size:100%; 
	}
	// @keyframes rotating {
	// 	from { -webkit-transform: rotate(0) }
	// 	to { -webkit-transform: rotate(360deg) }
	// }
	// @-webkit-keyframes rotating {
	// 	from { -webkit-transform: rotate(0) }
	// 	to { -webkit-transform: rotate(360deg) }
	// }
	.isOff{
		width: 0px;
		height: 0px;
		position: fixed;
		z-index: 2000;
		
		background: url("./assets/images/q2/pkin/no1.png") 0 0 no-repeat;
		background-size:100%; 
	}
	
	.van-button--info {
		background: #ffd322 !important;
		color: #fff !important;
		border: none !important;
	}

	.Title {
		text-align: center;
		// background-color: #131426;
		// border: 1px solid #1F223E;
		position: relative;
		 background: url('./assets/images/985/box/title.png') no-repeat center;
    	background-size: 130% 20%;
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0;
		// 	left: 10px;
		// 	height: 100%;
		// 	width: 52px;
		// 	background: url(./assets/images/public/ZIleft.png) 100% no-repeat;
		// }



		// &::before {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0;
		// 	right: 10px;
		// 	height: 100%;
		// 	width: 52px;
		// 	background: url(./assets/images/public/Ziright.png) 100% no-repeat;
		// }

		p {
			display: inline-block;
			margin: 0 auto;
			color: #fff;
			font-size: 0.6rem !important;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			text-shadow: 0 0 15px hsla(0, 0%, 100%, .5);
			padding: 20px 56px;
			clip-path: polygon(13px 0, calc(100% - 13px) 0, 100% 50%, calc(100% - 13px) 100%, 13px 100%, 0 50%);
			
		}
	}

	.van-popup {
		z-index: 10003 !important;
		// background: #6290bf !important;
		color: #000 !important;
	}

	.el-pagination {
		text-align: center;
		padding: 0 !important;

		.btn-prev {
			width: 60px !important;
			background: #2e3850 !important;
			color: #fff !important;
			background: url('./assets/images/q2/tui/leftright.png') no-repeat center !important;
			background-size: 100% 100% !important;
		}

		.btn-next {
			width: 60px !important;
			background: #2e3850 !important;
			color: #fff !important;
			background: url('./assets/images/q2/tui/leftright.png') no-repeat center !important;
			background-size: 100% 100% !important;
		}

		.el-pager {
			li {
				background: #2e3850 !important;
				color: #fff !important;
				margin: 0 2.3px !important;
				background: url('./assets/images/q2/tui/number.png') no-repeat center !important;
				background-size: 100% 100% !important;
				&.active {
					background: url('./assets/images/q2/tui/active.png') no-repeat center !important;
					background-size: 100% 100% !important;
				}
			}
		}
	}

	.van-overlay {
		z-index: 10001 !important;
		backdrop-filter: blur(5px);
		.wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;

			.LogOut_box {
				width: 90%;
				padding: 20px;
				box-sizing: border-box;
				background: #20222b;
				border-radius: 10px;
				position: relative;

				.close {
					position: absolute;
					top: 10px;
					right: 10px;
					width: 20px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.tit {
					width: 100%;
					text-align: center;
					font-size: 22px;
					color: #fff;
					margin-bottom: 5px;
				}

				.text {
					text-align: center;
					color: #fff;
					font-size: 14px;
					margin: 10px 0;
				}

				.van-cell {
					background: none;
					margin-top: 10px;

					.van-field__label {
						width: 50px;
						color: #fff;
					}

					.van-field__control {
						color: #fff;
					}
				}

				.van-cell::after {
					background: #fff;
				}
			}
		}
	}
	@mixin url($url) {
    background-image: url('~@/assets/images/'+$url);
}

//定位全屏
@mixin allcover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
}

//定位上下左右居中
@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//定位上下左右居中
@mixin flex($z) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: $z;
}

//定位上下居中
@mixin ct {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

//定位左右居中
@mixin cl {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

}
//定位左右居中
@mixin btn($width,$height,$color) {
    width: $width;
    height: $height;
    line-height: $height;
    text-align: center;
    border: $color 1px solid;
    color: $color;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
        background-color: $color;
        color:  $color;
    }
}

//定位左右居中
@mixin input($color) {
    .el-input{
        input::-webkit-input-placeholder {
            color:$color;
            font-size: 16px;
        }
    }
    .el-input__inner {
        border-radius: 0;
        background-color: rgba($color: $color, $alpha: 0.2);
        border-color: $color;
        color: $color;
    }
    .el-input__inner:focus {
        border-color: $color;
    }
}

@mixin title{
    text-shadow: rgb(0, 0, 0) .03rem .03rem 0px;
    width: 3rem;
    position: relative;
    font-weight: 700;
    font-size: .2rem;
    letter-spacing: .05rem;
    padding: .1rem;
    text-align: center;
    padding-top: .3rem;
    margin: 0 auto .3rem;
    &::after {
        height: 2px;
        content: "";
        width: 100%;
        left: 0px;
        bottom: 0;
        position: absolute;
        background: linear-gradient(
            90deg,
            transparent,rgba(255, 0, 0, 0.8),transparent
        );
        border-radius: 1px;
    }
}

@mixin texthide {
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

@function lv-img($lv){
    $url : '~@/assets/images/';
    $img : '';
    @if $lv==1 {
        $img: 'Roll/yellow.png';
    }@else if $lv==2 {
        $img: 'Roll/red.png';
    }@else if $lv==3 {
        $img: 'Roll/purple.png';
    }@else if $lv==4 {
        $img: 'Roll/blue.png';
    }@else if $lv==5 {
        $img: 'Roll/grey.png';
    }
    @return $url + $img;
}

@function lv-color($lv){
    @if $lv==1 {
        @return  #e6ba1c;
    }@else if $lv==2 {
        @return  #e62c2c;
    }@else if $lv==3 {
        @return  #834fff;
    }@else if $lv==4 {
        @return  #4b69ff;
    }@else if $lv==5 {
        @return  #bbbbbb;
    }
}

@function rem($px) {
    @return $px * 0.01rem;
}

.text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.bg-1{
    background:url('./assets/images/q2/openbox/1.png') no-repeat center !important;
	background-size: 100% 100% !important;
}

.bg-2{
    background:url('./assets/images/q2/openbox/2.png') no-repeat center !important;
	background-size: 100% 100% !important;
}
.bg-3{
    background: url('./assets/images/q2/openbox/3.png') no-repeat center !important;
	background-size: 100% 100% !important;
}
.bg-4{
    background: url('./assets/images/q2/openbox/4.png') no-repeat center !important;
	background-size: 100% 100% !important;
}
.bg-5{
	background: url('./assets/images/q2/openbox/5.png') no-repeat center !important;
	background-size: 100% 100% !important;
}
.show-1{
        box-shadow: 0 0 40px 10px #e6ba1c;
}
.show-2{
        box-shadow: 0 0 40px 10px #e62c2c;
}
.show-3{
        box-shadow: 0 0 40px 10px #834fff;
}
.show-4{
        box-shadow: 0 0 40px 10px #4b69ff;
}
.show-5{
        box-shadow: 0 0 40px 10px #bbbbbb;
}
//...
</style>
